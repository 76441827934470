import useCheckoutEstimate from "hooks/Subscriptions/useCheckoutEstimate";
import {
  useCheckout,
  useIPCountryName,
  useIPRegionName,
} from "modules/selectors";
import { GET_SUBSCRIPTION_DATA } from "modules/user/actions-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  BillingPeriodUnit,
  GatewayProvider,
  PaymentMethodType,
  SubscriptionProvider,
  useCreateSubscriptionMutation,
} from "services/graphql";
import {
  onboardingStartTrial,
  orderCompleted,
} from "services/typewriter/segment";
import { CheckoutError } from "constants/cart";
import { getStoredGCLID } from "hooks/useGetGclid";
import { usePlans } from "./usePlans";

interface SubscriptionOptions {
  gatewayProvider: GatewayProvider;
  paymentMethodType: PaymentMethodType;
  token: string;
}

interface Props {
  onSuccess?(): void;
}

export function useCreateSubscription(props?: Props) {
  const { onSuccess } = props || {};
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const checkout = useCheckout();
  const { refetch: refetchPlans } = usePlans();
  const { selectedPlan, coupon } = checkout;
  const { canTrial } = selectedPlan;
  const { checkoutEstimate } = useCheckoutEstimate();
  const { sendTrialStartedEvent } = useSendTrialStartedEvent();

  const [
    execCreateSubscription,
    createSubscriptionResults,
  ] = useCreateSubscriptionMutation();
  const countryName = useIPCountryName();
  const regionName = useIPRegionName();

  const createSubscription = async ({
    gatewayProvider,
    paymentMethodType,
    token,
  }: SubscriptionOptions) => {
    if (canTrial) {
      sendTrialStartedEvent();
    }

    await execCreateSubscription({
      variables: {
        planInfo: {
          id: selectedPlan.provider.id,
          name: SubscriptionProvider.Chargebee,
        },
        gateway: {
          provider: gatewayProvider,
          token,
        },
        paymentMethodType,
        couponIds: coupon ? [coupon] : null,
        ipInfo: {
          countryName,
          regionName,
        },
      },
      onCompleted: ({ subscriptionCreate: subscriptionCreateData }) => {
        dispatch({
          type: GET_SUBSCRIPTION_DATA,
          results: {
            subscription: subscriptionCreateData,
            isActive: subscriptionCreateData?.user?.isActive,
            hasTrialed: subscriptionCreateData?.user?.hasTrialed,
          },
        });

        const plan = subscriptionCreateData?.plan;

        if (plan) {
          orderCompleted({
            order_id: subscriptionCreateData.provider.id,
            coupon,
            products: [
              {
                product_id: plan.provider.id,
                sku: plan.provider.id,
                price: plan.priceInCents / 100,
                quantity: 1,
                name: canTrial
                  ? `${plan.provider.id}_trialing`
                  : plan.provider.id,
              },
            ],
          });
        }

        if (canTrial) {
          const trialLength = canTrial
            ? subscriptionCreateData?.plan?.trialPeriod
            : 0;
          const utmParams = JSON.parse(localStorage.getItem("utm_params"));
          const firstBillingDate = new Date(
            subscriptionCreateData?.nextBillingAt
          );
          const renewalDate = new Date(subscriptionCreateData?.nextBillingAt);
          if (plan.periodUnit === BillingPeriodUnit.Year) {
            renewalDate.setFullYear(renewalDate.getFullYear() + 1);
          } else {
            renewalDate.setMonth(renewalDate.getMonth() + 1);
          }

          let discountData = {};
          if (checkoutEstimate) {
            const { coupon: couponResponse } = checkoutEstimate;

            discountData = {
              discount:
                couponResponse.discountType === "fixed_amount"
                  ? couponResponse.discountAmount
                  : (subscriptionCreateData?.plan?.priceInCents *
                      (couponResponse.discountPercentage / 100)) /
                    100,
              discount_amount: couponResponse.discountAmount,
              discount_percent: couponResponse.discountPercentage,
              plan_price_after_discount:
                checkoutEstimate.discountPlanPriceInCents / 100,
            };
          }

          onboardingStartTrial({
            ...utmParams,
            ...discountData,
            coupon,
            platform: "web",
            payment_method: paymentMethodType,
            subscription_start_date: firstBillingDate.toISOString(),
            subscription_renewal_date: renewalDate.toISOString(),
            trial_length: trialLength,
            path: location.pathname,
            plan: subscriptionCreateData?.plan.provider.id,
            plan_price: subscriptionCreateData?.plan.priceInCents / 100,
            subscription_id: subscriptionCreateData?.provider.id,
            order_id: subscriptionCreateData?.provider.id,
            products: [
              {
                product_id: subscriptionCreateData?.plan.provider.id,
                price: subscriptionCreateData?.plan.priceInCents / 100,
                quantity: 1,
              },
            ],
            gclid: getStoredGCLID(),
          });
        }

        // Refetch plans to get new invoice estimates if user wants to switch or upgrade
        refetchPlans();

        if (onSuccess) {
          onSuccess();
        } else {
          history.push("/dashboard");
        }
      },
      onError: () => {
        throw new Error(CheckoutError.Generic);
      },
    });
  };

  return { createSubscription, createSubscriptionResults };
}

function useSendTrialStartedEvent() {
  const { email } = useSelector((state: any) => state.auth);

  function sendTrialStartedEvent() {
    window.analytics.page({
      title: "Free Trial Created",
      path: "/free-trial-created-7",
      idt_email: email,
    });
  }

  return { sendTrialStartedEvent };
}
